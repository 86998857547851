<template>
  <div>
		<vs-row v-show="isOneTime">
			<vs-col vs-w="12">
				<customer-onetime-data :onetimeCustomerData="onetimeCustomer" @data="dataOneTime" @reset="resetForm" :isOneTime="isOneTime"/>
			</vs-col>
		</vs-row>
    <vs-row>
      <vs-col vs-w="6">
        <p class="text-lg">Customer Data</p>
        <hr />
        <!-- <div class="my-2" v-if="form.so_types == 'without_so_reference'"> -->
        <div class="my-2">
          <label class="vs-input--label">One Time</label>
          <div class="">
            <vs-switch
              color="dark"
              icon-pack="feather"
              vs-icon-on="icon-check-circle"
              vs-icon-off="icon-slash"
              v-model="isOneTime"
            >
              <span slot="on">YES</span>
              <span slot="off">NO</span>
            </vs-switch>
          </div>
        </div>
        <div class="my-2" v-show="form.so_types == 'without_so_reference'">
          <label class="vs-input--label">Select Territory</label>
          <SelectTerritory
            @data="setSelectTerritory"
            ref="selectTerritory"
            :isDisable="form.isEdit || form.so_types == 'with_so_reference'"
            :params="this.terr_params"
          />
        </div>

        <div class="my-2">
          <label class="vs-input--label">Pickup From / Ship To</label>
          <SelectCustomerAddress
            @data="setSelectCustomerAddress"
            ref="selectCustomerAddress"
            :isDisabled="
              form.so_types == 'with_so_reference' ||
              form.isDetail == 1 ||
              form.isEdit
            "
            :formDatas="form"
          />
        </div>

        <div class="my-2">
          <label class="vs-input--label">Customer</label>
          <SelectCustomer
            @data="setSelectCustomer"
            ref="selectCustomer"
            :isWithoutSoRef="form.so_types == 'without_so_reference'"
            :isDisabled="
              form.so_types == 'with_so_reference' ||
              form.isDetail == 1 ||
              form.isEdit
            "
            :isLocked="this.form.customer_return_data.customer_ship_to != 0 && this.form.customer_return_data.customer_ship_to != undefined"
          />
        </div>

        <div class="my-2">
          <label class="vs-input--label">Customer Name</label>
          <vs-input
            readonly
            class="w-full"
            v-model="form.customer_data.customer_name"
            :disabled="form.isDetail == 1"
          />
        </div>

        <div class="my-2">
          <label class="vs-input--label">Customer Sold To</label>
          <SelectCustomerBillTo
            @data="setSelectCustomerBillTo"
            ref="selectCustomerBillTo"
            :isDisabled="
              form.so_types == 'with_so_reference' ||
              form.isDetail == 1 ||
              form.isEdit
            "
          />
        </div>

        <!-- MIX2-10274 field salesman on SR without ref -->
        <div class="my-2" v-if="form.so_types == 'without_so_reference'">
          <label class="vs-input--label">Select Salesman</label>
          <multiselect
            class="selectExample"
            v-model="salesPersonal.selected"
            :options="salesPersonal.option"
            :multiple="false"
            :allow-empty="true"
            :max-height="120"
            :limit="3"
            placeholder="Type to search"
            :loading="isLoadingSales"
            track-by="ID"
            @search-change="handlerSearchSales"
            @input="onselectedSalesman"
            @change="onChangeSalesman"
            :custom-label="customLableSalesman"
            :disabled="(form.isDetail == 1 || form.so_types == 'with_so_reference') && !form.isEdit"
          ></multiselect>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import SelectCustomer from "@/components/master/SelectCustomer.vue";
import SelectCustomerAddress from "@/components/master/SelectCustomerAddrSr.vue";
import SelectCustomerBillTo from "@/components/master/SelectCustomerBillTo.vue";
import SelectTerritory from "@/components/master/TerritorySR.vue";
import CustomerOnetimeData from "./customer-onetime.vue";
import { readonly } from "vue";

export default {
  components: {
    SelectCustomer,
    SelectCustomerAddress,
    SelectCustomerBillTo,
    SelectTerritory,
    CustomerOnetimeData,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    isDisabledFormInvoice: {
      type: Boolean,
      default: false,
    },
    terr_params: null,
    onetimeCustomer: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    if (this.onetimeCustomer.id>0) {
      this.isOneTime = true
    }else{
      // this.resetForm()
      this.readOnlyOneTime = false
      this.isOneTime = false
    }
    if (this.form.customer_data.customer_id) {
      this.$refs.selectCustomer.setIds(this.form.customer_data.customer_id);
    }
    if (this.form.customer_data.customer_code) {
      this.$refs.selectCustomer.setCodes(this.form.customer_data.customer_code);
    }
    if (this.form.customer_data.customer_bill_to_id) {
      this.$refs.selectCustomerBillTo.setByID(
        this.form.customer_data.customer_bill_to_id
      );
    }
    if (this.form.so_types == "with_so_reference") {
      this.$refs.selectCustomer.setIsDisabled(true);
    }

    if (this.form.isDetail == 1) {
      this.$refs.selectCustomer.setDisabled(true);
    }
    if (this.form.isDetail == 1 || this.form.isEdit == 1) {
      this.$refs.selectCustomerAddress.setDisabled(true);
      this.$refs.selectCustomerBillTo.setDisabled(true);
    }
    if (this.form.srID != null && this.form.sales_personal_id != null) {
      this.getOptionSalesID(true);
    }
  },
  methods: {
    async setSelectTerritory(data) {
      console.log("this.form")
      console.log(this.form)
      console.log("ini data ?", data)
      // set customer by territory
      var terrID = null;
      if (this.form.srID != null) {
        console.log("ini edit data ?")
        console.log("this.form", this.form)
      } else {
        if (data.id) {
          terrID = data.id;
          this.form.territory_id = data.id;
          this.$refs.selectCustomer.setParams({
            territory_id: [data.id],
          });
        }
        await this.$refs.selectCustomer.getData();
        await this.$refs.selectCustomerAddress.getDataShipTo({
          params: {
            territory_id: terrID,
            so_type: "sales_return",
            is_default: false,
          },
        });
        await this.$refs.selectCustomerBillTo.setFilters({
          territory_id: data.id,
        });
        await this.$refs.selectCustomerBillTo.getData();
        await this.getOptionSalesID(false);
      }
    },
    async setSelectCustomer(data) {
      if (this.form.so_types == "with_so_reference") {
        await this.$refs.selectCustomer.toggleDisabled();
      }

      const name = data.name;
      let customer_id = 0;
      if (data.ID != null) {
        this.form.customer_data.customer_id = data.ID;
        customer_id = data.ID;
      } else if (data.id != null) {
        this.form.customer_data.customer_id = data.id;
        customer_id = data.id;
      }
      this.form.customer_data.selected_customer_id = customer_id;
      this.form.customer_data.customer_name = name;
      this.form.customer_data.selected_customer = data;
      this.form.customer_data.pricing_group_id = data.pricing_group_id;
      this.form.customer_data.customer_group_id_1 = data.customer_group_id1;
      this.form.customer_data.customer_group_id_2 = data.customer_group_id2;
      this.form.customer_data.customer_group_id_3 = data.customer_group_id3;

      let shipTo_id = null;
      if (this.form.customer_return_data.customer_ship_to != 0) {
        shipTo_id = this.form.customer_return_data.customer_ship_to;
      }

      if (this.form.customer_invoice_data.sales_order_data != null) {
        shipTo_id = this.form.customer_invoice_data.sales_order_data.ShipmentToAddressID != 0 ? this.form.customer_invoice_data.sales_order_data.ShipmentToAddressID : null;
      }

      let territory_id = null;
      if (this.form.territory_id != 0) {
        territory_id = this.form.territory_id;
      }
      console.log({
        id: shipTo_id,
        territory_id: territory_id,
        customer_id: customer_id,
      });
      // set customer address -- dimatikan karena bikin infinite tsukuyomi, eh infitine loops
      await this.$refs.selectCustomerAddress.getDataShipTo({
        params: {
          id: shipTo_id,
          territory_id: territory_id,
          customer_id: customer_id,
          so_type: "sales_return",
          is_default: true,
        },
      });
      // set customer bill to
      // if (this.form.customer_data.customer_bill_to_id) {
      await this.$refs.selectCustomerBillTo.setFilters({
        customer_id: customer_id,
      });
      await this.$refs.selectCustomerBillTo.getData();
    },
    async setSelectCustomerAddress(data) {
      console.log("this.form", this.form)
      console.log("data", data)
      if (!data && this.form.so_types == "with_so_reference") {
        data ={
          territory_id:this.form.customer_return_data.territory_warehouse.territory_id,
          customer_id:this.form.customer_data.customer_id
        }
      }
      if (this.form.customer_data.selected_customer_id == null || this.form.customer_return_data.customer_ship_to != data.ID) {
        this.$refs.selectTerritory.getData({params : {id:data.territory_id}});
      }
      
      if (this.form.customer_data.selected_customer_id == null || this.form.customer_data.selected_customer_id != data.customer_id) {
        this.$refs.selectCustomer.setIds(data.customer_id);
      }

      this.form.customer_data.pickup_from_ship_to = data;
      this.form.customer_return_data.customer_ship_to = data.ID
      if (this.form.isDetail != 1 && !this.form.isEdit) {
        this.form.sales_personal_id = data.sales_personal_id != 0 ? data.sales_personal_id : this.form.sales_personal_id;
      }
      console.log("this.form.customer_return_data.customer_ship_to", this.form.customer_return_data.customer_ship_to)
      await this.getOptionSalesID(true);
    },
    async setSelectCustomerBillTo(data) {
      this.form.customer_data.customer_sold_to = data;
    },
    async getOptionSalesID(is_default) {
      this.$vs.loading();
      console.log("dipanggil")
      try {
        let superadmin
        this.$userLogin.roles.filter(function(item) {
          if(item.Name == "superadmin"){
            superadmin = true;
          }else{
            superadmin = false;
          }
        });
        const resp = await this.$http.get("/api/v1/sales-order/personals",{
          params: {
            search: this.searchSales,
            territory_id: this.form.territory_id ? this.form.territory_id : null,
            sales_personal_id: this.form.sales_personal_id ? this.form.sales_personal_id : null,
          }
        });
        console.log("resp",resp);
        if (resp.code == 200) {
          this.salesPersonal.option = []
          this.salesPersonal.all_options = []
          let data = resp.data.salesman
          if (this.salesPersonal.selected != null) {
            this.salesPersonal.option.push(this.salesPersonal.selected)
            this.salesPersonal.all_options.push(this.salesPersonal.selected)
          }
          for(var idx = 0;idx<data.length;idx++){
            this.salesPersonal.option.push(data[idx])
            this.salesPersonal.all_options.push(data[idx])
          }
          if (!superadmin) {
            // set default Sales ID jika bukan superadmin
            this.salesPersonal.selected = this.salesPersonal.option.filter((v) => {
              return v.ID == this.$userLogin.id;
            });
          }
          if (is_default && this.form.customer_data.pickup_from_ship_to.sales_personal_id != 0 && data.length == 1) {
            this.salesPersonal.selected = resp.data.salesman[0];
          }
          if (is_default && this.form.customer_data.pickup_from_ship_to.sales_personal_id != 0 && data.length > 1) {
            this.salesPersonal.selected = this.salesPersonal.option.filter((v) => {
              return v.ID == this.form.customer_data.pickup_from_ship_to.sales_personal_id;
            });
          }
          if (this.form.sales_personal_id != 0 && data.length >= 1) {
            this.salesPersonal.selected = this.salesPersonal.option.filter((v) => {
              return v.ID == this.form.sales_personal_id;
            });
            this.salesPersonal.selected = this.salesPersonal.selected[0];
          }
          console.log("this.form.customer_data.pickup_from_ship_to.sales_personal_id", this.form.customer_data.pickup_from_ship_to.sales_personal_id)
          console.log("this.form", this.form)
          console.log("this.salesPersonal", this.salesPersonal)
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Error while fetching salesman",
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        }
      } catch (error) {
        // this.$vs.notify({
        //   title: "Error",
        //   text: "Error while fetching salesman",
        //   color: "danger",
        //   iconPack: "feather",
        //   icon: "icon-alert-circle",
        // });
        console.log(error);
      }
      this.$vs.loading.close();
    },
    async handlerSearchSales(search){
      console.log("masuk sini lagi")
      console.log("salesPersonal.selected", this.salesPersonal.selected)
      console.log("salesPersonal.selected", this.form)
      
      this.searchSales = search
      if(search != ""){
        this.form.sales_personal_id = null
        await this.getOptionSalesID(false);
      }
    },
    customLableSalesman({ Name, CodeExternal }){
      return (CodeExternal != "" && CodeExternal) ? `[ ${CodeExternal} ] ${Name}` : `[ - ] ${Name}`;
    },
    onselectedSalesman(select){
      console.log("onselectedSalesman", select)
      if(select==null && this.form.so_types == "without_so_reference"){
				this.$vs.notify({
					title: "Error",
					text: "Please select salesman !",
					color: "danger",
				});
        this.$emit("selectSalesman", select);
        return
			}
      this.form.sales_personal_id = select.ID
      // aa
      console.log("this.form.sales_personal_id", this.form.sales_personal_id)
      console.log("this.form", this.form)
      this.$emit("selectSalesman", select);
    },
    onChangeSalesman(select){
      console.log("onChangeSalesman", select)
      if(select==null && this.form.so_types == "without_so_reference"){
				this.$vs.notify({
					title: "Error",
					text: "Please select salesman !",
					color: "danger",
				});
        this.$emit("selectSalesman", select);
        return
			}
      this.form.sales_personal_id = select.ID
      // aa
      console.log("this.form.sales_personal_id", this.form.sales_personal_id)
      console.log("this.form", this.form)
      this.$emit("selectSalesman", select);
    },
    dataOneTime(data){
      console.log("data customer one time",data)
      if (data.status=="failed") {
        this.isOneTime = false
      }
      if(data.status=="onetime"){
        this.setSelectCustomerAddress(data.data_ori.address_records)
        // this.dataFinalOnetime = data.data_ori.records
        this.dataFinalOnetime.customer = data.data_ori.records
        // this.dataFinalOnetime.
        this.dataFinalOnetime.id = data.data.id
        this.dataFinalOnetime.territory_id = data.data.territory_id
        this.dataFinalOnetime.name = data.data.name
        this.dataFinalOnetime.contact_person = data.data.contact_person
        this.dataFinalOnetime.nik = data.data.nik
        this.dataFinalOnetime.nitku = data.data.nitku
        this.dataFinalOnetime.phone = data.data.phone
        this.dataFinalOnetime.domisili = data.data.domisili
        this.dataFinalOnetime.npwp_type = data.data.npwp_type
        this.dataFinalOnetime.npwp = data.data.npwp
        this.dataFinalOnetime.npwp_name = data.data.npwp_name
        this.dataFinalOnetime.sppkp = data.data.sppkp
        this.dataFinalOnetime.tax_address = data.data.tax_address
        this.dataFinalOnetime.address = data.data.address
        this.dataFinalOnetime.postalCode = data.data.postalCode
        this.dataFinalOnetime.countryName = data.data.countryName
        this.dataFinalOnetime.provinceName = data.data.provinceName
        this.dataFinalOnetime.cityName = data.data.cityName
        this.dataFinalOnetime.districtName = data.data.districtName
        this.dataFinalOnetime.subdistrictName = data.data.subdistrictName

        this.readOnlyOneTime = false
      }else if (data.status=="inv-onetime"){
        setTimeout(() => {
          this.$refs.selectCustomer.setName(data.data.name);
          this.$refs.selectCustomerAddress.setContactName(data.data.contact_person);
          this.$refs.selectCustomerAddress.setAddress(data.data.address);
          this.$refs.selectCustomerBillTo.setContactName(data.data.contact_person);
          this.$refs.selectCustomerBillTo.setAddress(data.data.address);
        }, 2000)
      }else if(data.status=="update-data"){
        this.dataFinalOnetime.id = data.data.id
        this.dataFinalOnetime.territory_id = data.data.territory_id
        this.dataFinalOnetime.name = data.data.name
        this.dataFinalOnetime.contact_person = data.data.contact_person
        this.dataFinalOnetime.nik = data.data.nik
        this.dataFinalOnetime.nitku = data.data.nitku
        this.dataFinalOnetime.phone = data.data.phone
        this.dataFinalOnetime.domisili = data.data.domisili
        this.dataFinalOnetime.npwp_type = data.data.npwp_type
        this.dataFinalOnetime.npwp = data.data.npwp
        this.dataFinalOnetime.npwp_name = data.data.npwp_name
        this.dataFinalOnetime.sppkp = data.data.sppkp
        this.dataFinalOnetime.tax_address = data.data.tax_address
        this.dataFinalOnetime.address = data.data.address

        // this.dataFinalOnetime.postalCode = data.data.postalCode
        // this.dataFinalOnetime.countryName = data.data.countryName
        // this.dataFinalOnetime.provinceName = data.data.provinceName
        // this.dataFinalOnetime.cityName = data.data.cityName
        // this.dataFinalOnetime.districtName = data.data.districtName
        // this.dataFinalOnetime.subdistrictName = data.data.subdistrictName
        
        if (data.data.postalCode!="") {
          this.dataFinalOnetime.postalCode = data.data.postalCode
        }
        if (data.data.countryName!="") {
          this.dataFinalOnetime.countryName = data.data.countryName
        }
        if (data.data.provinceName!="") {
          this.dataFinalOnetime.provinceName = data.data.provinceName
        }
        if (data.data.cityName!="") {
          this.dataFinalOnetime.cityName = data.data.cityName
        }
        if (data.data.districtName!="") {
          this.dataFinalOnetime.districtName = data.data.districtName
        }
        if (data.data.subdistrictName!="") {
          this.dataFinalOnetime.subdistrictName = data.data.subdistrictName
        }
      }else if (data.status=="btn-update-data") {
        this.$refs.selectCustomer.setName(data.data.name);
        this.$refs.selectCustomerAddress.setContactName(data.data.contact_person);
        this.$refs.selectCustomerAddress.setAddress(data.data.address);
        this.$refs.selectCustomerBillTo.setContactName(data.data.contact_person);
        this.$refs.selectCustomerBillTo.setAddress(data.data.address);
        this.form.customer_data.customer_name = data.data.name
      }
      this.readOnlyOneTime = data.readonly
    },
    async setOneTime(v = false){
      this.isOneTime = v
    },
    async submitCustOnetime(tax_id = 0){
      return new Promise((resolve, reject) => {
        let form_empty;
        // Buat save customer onetime disini, ke tabel baru
        form_empty = (this.dataFinalOnetime.name == null || this.dataFinalOnetime.name == "") || form_empty;
        form_empty = (this.dataFinalOnetime.contact_person == null || this.dataFinalOnetime.contact_person == "") || form_empty;
        form_empty = (this.dataFinalOnetime.nik == null || this.dataFinalOnetime.nik == "") || form_empty;
        form_empty = (this.dataFinalOnetime.phone == null || this.dataFinalOnetime.phone == "") || form_empty;

        if (this.dataFinalOnetime.nitku != null && this.dataFinalOnetime.nitku != "") {
          if (this.dataFinalOnetime.nitku.trim() == "" || this.dataFinalOnetime.nitku.trim().length != 22) {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: "The NITKU field must be 22 digits.",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
            reject({
              code:500,
            })
            return
          }
        } else {
          if (this.dataFinalOnetime.nitku == null || this.dataFinalOnetime.nitku == "") {
            this.$vs.notify({
                color: "danger",
                title: "Error",
                text: "The NITKU field is required.",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
              reject({
              code:500,
            })
            return
          }
        }
        if(form_empty){
          this.$vs.notify({
            title: "Error",
            text: "Please fill form correctly !",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
          reject({
            code:500,
          })
          return;
        }
        if (this.dataFinalOnetime.npwp&&(this.dataFinalOnetime.npwp.length != 15 && this.dataFinalOnetime.npwp.length != 16)) {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "NPWP must be 15 or 16 characters!",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          reject({
            code:500,
          })
          return;
        }
        if (!this.dataFinalOnetime.nik||(this.dataFinalOnetime.nik&&this.dataFinalOnetime.nik.length != 16)) {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "required 16 characters for NIK!",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          reject({
            code:500,
          })
          return;
        }
        let params, url;
        url = this.dataFinalOnetime.id >0 ?
            "/api/v1/sales-order/onetime_cust/" + this.dataFinalOnetime.id :
            "/api/v1/sales-order/onetime_cust/store";
        params = {
          type: this.dataFinalOnetime.customer.Type,
          customer_id: this.dataFinalOnetime.customer.ID,
          payment_method_id: this.dataFinalOnetime.customer.PaymentMethodID,
          payment_term_id: this.dataFinalOnetime.customer.PaymentTermID,

          
          country: this.dataFinalOnetime.countryName,
          province: this.dataFinalOnetime.provinceName,
          city: this.dataFinalOnetime.cityName,
          district: this.dataFinalOnetime.districtName,
          sub_district: this.dataFinalOnetime.subdistrictName,
          postal_code: this.dataFinalOnetime.postalCode,
          address: this.dataFinalOnetime.address,
          name: this.dataFinalOnetime.name,
          contact_person: this.dataFinalOnetime.contact_person,
          nik: this.dataFinalOnetime.nik,
          nitku: this.dataFinalOnetime.nitku,
          phone: this.dataFinalOnetime.phone,
          domisili: this.dataFinalOnetime.domisili,
          npwp_type: this.dataFinalOnetime.npwp_type,
          npwp: this.dataFinalOnetime.npwp,
          npwp_name: this.dataFinalOnetime.npwp_name,
          sppkp: this.dataFinalOnetime.sppkp,
          tax_address: this.dataFinalOnetime.tax_address,
          tax_id: tax_id+"",
        };

        // console.log('params', params)
        this.$http.post(url, params).then(resp => {
          // console.log(url, resp);
          if (resp.code == 200) {

            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check-circle",
            });
            resolve(resp)
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
            reject(resp)
          }
        });
      });
    },
    resetForm(){
      this.oneTimeDataForm = {
        id:0,
				territory_id:0,
        name:null,
        contact_person:null,
        nik:null,
        phone:null,
        domisili:null,
        npwp_type:null,
        npwp:null,
        npwp_name:null,
        sppkp:null,
        tax_address:null,
        address :null,
        postalCode :null,
        countryName :null,
        provinceName :null,
        cityName :null,
        districtName :null,
        subdistrictName :null,
      }
      this.formShow = false
      this.$refs.selectCustomer.resetForm();
      this.$refs.selectCustomerAddress.resetForm();
      this.$refs.selectCustomerBillTo.resetForm();
      this.form.customer_data.selected_customer_id = null
      this.form.customer_data.customer_name = null
    },
  },
  watch: {
    "terr_params.params.id": {
      handler: function (val) {
        this.$refs.selectTerritory.setData(val);
      },
    },
    "form.customer_data.customer_id": {
      handler: function (val) {
        this.$refs.selectCustomer.setIds(val);
      },
    },
    "form.customer_data.customer_code": {
      handler: function (val) {
        if (val != null) {
          this.$refs.selectCustomer.setCodes(val);
        }
      },
    },
    "form.customer_data.customer_bill_to_id": {
      handler: function (val) {
        if (val != null) {
          this.$refs.selectCustomerBillTo.setByID(val);
        }
      },
    },
    "form.so_types": {
      handler: function (val) {
        console.log("this.form", this.form)
        if (val == "with_so_reference") {
          this.$refs.selectCustomer.setIsDisabled(true);
          if (this.form.customer_invoice_data == null || this.form.customer_invoice_data.customer_invoice_id == null){
            this.form.customer_data.customer_name = null;
          }
        } else {
          this.$refs.selectCustomer.setIsDisabled(false);
          this.form.customer_data.customer_name = null;
        }
      },
    },
    "form.isDetail": {
      handler: function (val) {
        if (val == 1) {
          this.$refs.selectCustomer.setDisabled(true);
          this.$refs.selectCustomerAddress.setDisabled(true);
          this.$refs.selectCustomerBillTo.setDisabled(true);
        }
      },
    },
    "onetimeCustomer.id":{
      handler: function (val) {
        console.log("horeeeeee,",val)
        if (val>0) {
          this.isOneTime = true
        }else{
          this.resetForm()
          this.readOnlyOneTime = false
          this.isOneTime = false
        }
      }
    },
    "isOneTime":{
      handler:function(val){
        console.log("is one time val,",val)
        console.log("this.form.territory_id,",this.form.territory_id )
        console.log("this.onetimeCustomer isonetime,",this.onetimeCustomer)
        console.log("this.form.so_types,",this.form.so_types )
        if(this.form.so_types != 'with_so_reference'&&this.form.territory_id >0){
          this.onetimeCustomer.territory_id = this.form.territory_id
        }
        console.log("this.onetimeCustomer.territory_id after,",this.onetimeCustomer.territory_id )

        if (val) {
          if (this.form.so_types == 'with_so_reference' && this.onetimeCustomer.id < 1) {
            this.$vs.notify({
              title: "Error",
              text: "This invoice is not for a “one time” customer!",
              color: "danger",
            });
            this.isOneTime = false
            this.readOnlyOneTime = this.isOneTime
            val = this.isOneTime
          }else if(!this.form.so_types){
            this.$vs.notify({
              title: "Error",
              text: "Please select so type first!",
              color: "danger",
            });
            this.isOneTime = false
            this.readOnlyOneTime = this.isOneTime
            val = this.isOneTime
          }else if(this.form.so_types != 'with_so_reference'&&this.form.territory_id < 1){
            this.$vs.notify({
              title: "Error",
              text: "Please select territory first!",
              color: "danger",
            });
            this.isOneTime = false
            this.readOnlyOneTime = this.isOneTime
            val = this.isOneTime
          }
        }else{
          if (this.readOnlyOneTime) {
            this.isOneTime = true
          }else{
            // this.onetimeCustomer.id = 0
            // this.onetimeCustomer.territory_id = 0
          }
        }
        if (val) {
          this.$refs.selectCustomer.setDisabled(true);
          this.$refs.selectCustomerAddress.setDisabled(true);
          this.$refs.selectCustomerBillTo.setDisabled(true);
        }else if(this.form.isDetail!=1 &&this.form.so_types != "with_so_reference"){
          this.$refs.selectCustomer.setDisabled(false);
          this.$refs.selectCustomerAddress.setDisabled(false);
          this.$refs.selectCustomerBillTo.setDisabled(false);
        }
        if (!val) {
          this.resetForm()
        }
        this.$emit("is_onetime", val);
      }
    },
  },
  data(){
    return{
      salesPersonal: {
        selected: null,
        option: [],
        all_options: [],
      },
      isLoadingSales:false,
      searchSales:"",
      isOneTime:false,
      readOnlyOneTime:false,
      dataFinalOnetime:{
        id:0,
        territory_id:0,
        customer:{},        
        address:"",
        postalCode:"",
        countryName:"",
        provinceName:"",
        cityName:"",
        districtName:"",
        subdistrictName:"",
        name:"",
        contact_person:"",
        nik:"",
        nitku:"",
        phone:"",
        domisili:"",
        npwp_type:"",
        npwp:"",
        npwp_name:"",
        sppkp:"",
        tax_address:"",
        tax_id:0,
      },
    }
  },
};
</script>
